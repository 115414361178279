@import "../../_globalColor";

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

/* Fix visible slider checkbox */
input {
  transform: scale(0.5);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $toggleSwitchSliderBG;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.eng::before {
  // background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjAgMzAiIGhlaWdodD0iNjAwIj4NCjxkZWZzPg0KPGNsaXBQYXRoIGlkPSJ0Ij4NCjxwYXRoIGQ9Im0zMCwxNWgzMHYxNXp2MTVoLTMwemgtMzB2LTE1enYtMTVoMzB6Ii8+DQo8L2NsaXBQYXRoPg0KPC9kZWZzPg0KPHBhdGggZmlsbD0iIzAwMjQ3ZCIgZD0ibTAsMHYzMGg2MHYtMzB6Ii8+DQo8cGF0aCBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iNiIgZD0ibTAsMGw2MCwzMG0wLTMwbC02MCwzMCIvPg0KPHBhdGggc3Ryb2tlPSIjY2YxNDJiIiBzdHJva2Utd2lkdGg9IjQiIGQ9Im0wLDBsNjAsMzBtMC0zMGwtNjAsMzAiIGNsaXAtcGF0aD0idXJsKCN0KSIvPg0KPHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEwIiBkPSJtMzAsMHYzMG0tMzAtMTVoNjAiLz4NCjxwYXRoIHN0cm9rZT0iI2NmMTQyYiIgc3Ryb2tlLXdpZHRoPSI2IiBkPSJtMzAsMHYzMG0tMzAtMTVoNjAiLz4NCjwvc3ZnPg0K");
  background: url("../../assets/flags/gb.svg");
  background-size: contain;
}

.ita::before {
  //background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjEwMDAiIHZpZXdCb3g9IjAgMCAzIDIiPg0KPHBhdGggZmlsbD0iIzAwOTI0NiIgZD0ibTAsMGgxdjJoLTF6Ii8+DQo8cGF0aCBmaWxsPSIjZmZmIiBkPSJtMSwwaDF2MmgtMXoiLz4NCjxwYXRoIGZpbGw9IiNjZTJiMzciIGQ9Im0yLDBoMXYyaC0xeiIvPg0KPC9zdmc+DQo=");
  background: url("../../assets/flags/it.svg");
  background-size: contain;
}

.slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 28px;
  left: 0;
  bottom: 0;
  background-color: $textColorDark;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $toggleCheck;
}

input:focus + .slider {
  box-shadow: $toggleCheck;
}

input:checked + .slider::before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}

.slider::after {
  display: inline;
  position: absolute;
  right: 9.3%;
  bottom: 17.5%;
}

input:checked + .slider::after {
  display: inline;
  position: absolute;
  right: 56.3%;
  bottom: 14.5%;
}

@media all and (max-width: 786px) and (min-width: 425px) {
  input:checked + .slider::after {
    right: 60.3%;
    bottom: 20.5%;
  }
  .slider::after {
    right: 15.3%;
    bottom: 22.5%;
  }
}

@media all and (max-width: 2560px) and (min-width: 1552px) {
  .slider::after {
    bottom: 12.5% !important;
  }
}

@media all and (max-width: 1552px) and (min-width: 1440px) {
  .slider::after {
    bottom: 7.5% !important;
  }
}
